@media (max-width: 1024px) {
    #hero {
        background-image: none;
        background-position-x: none;
        background-position-x: 0;
    }
}

@media (min-width: 1025px) and (max-width: 1999px) {
    #hero {
        background-image: url("/public/images/tc_hero_white.jpg");
        min-height: 90vh;
        background-position-x: 19rem; 
    }
}

@media (min-width: 2000px) {
    #hero {
        background-image: url("/public/images/tc_hero_white.jpg");
        min-height: 90vh;
        background-position-x: 30rem; 
    }
}
/* @media (max-width: 2560px) {
    #hero {
        background-image: url("/public/images/tc_hero_white.jpg");
        min-height: 90vh;
        background-position-x: 19rem; 
    }
} */



/* @media (max-width: 765px) {
    #hero {
        background-image: none;
        background-color: #f3f4f6;
    }
} */

#title {
    text-shadow: 2px 4px 5px rgba(0, 0, 0, 0.2);
}

#leading {
    text-shadow: 2px 4px 5px rgba(0,0,0,0.4);
}

#disclosure {
    text-shadow: 2px 4px 5px rgba(0,0,0,0.4);
}

#main_content {
    min-height: 100vh;
}