.navbar {
    height: 100px;
    background: transparent;
    top: 0;
    z-index: 49;
    width: 100%;
    position: fixed;
}

.navbar_acc {
    height: 100px;
    background: white;
    top: 0;
    z-index: 49;
    width: 100%;
    position: sticky;
}

.navbar.active {
    background: white;
    -webkit-box-shadow:0px 1px 1px #4a4a4a;
    -moz-box-shadow:0px 1px 1px #4a4a4a;
    box-shadow:0px -3px 10px #4a4a4a;
}